import React from 'react';
import { Typography, Paper, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Star, People, TrendingUp, Code } from '@mui/icons-material';

// Define your color palette
const darkBlue = '#1976d2'; // Dark blue for text and accents
const white = '#ffffff'; // White for background and text
const textColor = '#333'; // Default text color

// Styled components
const Section = styled('div')({
  padding: '20px 20px 60px 20px', // Adjusted padding: reduced top padding to remove extra space
  backgroundColor: white,
  marginTop: '0px', // Removed top margin
});

const SummaryCard = styled(Paper)({
  padding: '20px',
  marginBottom: '20px',
  textAlign: 'center',
  color: textColor,
  backgroundColor: white,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
});

const MetricCard = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: white,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  color: darkBlue,
  width: '220px',
});

const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',
});

const Summary = React.forwardRef((props, ref) => {
  return (
    <Section ref={ref}>
      <Typography variant="body1" align="center" paragraph color={textColor} style={{ fontSize: '18px' }}>
        I am driven by a vision to transform teams and technology. I deeply invests in people, relentlessly focus on technology excellence, fosters a culture of accountability.
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <Star color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Visionary Leadership</Typography>
            <Typography variant="body2">
              I set a strategic engineering direction, aligning our work with a broader purpose that inspires and unites. My leadership philosophy is rooted in clarity of vision—creating pathways, and empowering teams to turn that vision into reality.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <People color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Empowering People</Typography>
            <Typography variant="body2">
              My greatest pride lies in my people—engineers who are not just skilled, but are also emboldened to take ownership, challenge norms, and pursue excellence relentlessly. I lead with empathy, motivate with purpose, and cultivate a sense of belonging where every individual feels valued and driven to excel.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <TrendingUp color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Engineering Excellence</Typography>
            <Typography variant="body2">
              I champion engineering rigor and innovation, setting the highest standards for engineering quality. My approach is grounded in continuous improvement—driving systemic change that takes enginering  iteratitively to best in class.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <Code color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Delivering Impact</Typography>
            <Typography variant="body2">
             I lead with a clear focus on outcomes, ensuring every effort aligns with our mission and delivers tangible, measurable value. Under my leadership, teams don't just meet expectations—they redefine them, creating a legacy of success that resonates far beyond the immediate.
            </Typography>
          </MetricCard>
        </Grid>
      </Grid>
    </Section>
  );
});

export default Summary;
